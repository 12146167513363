import { debug } from './index';
import BezierGuide from './BezierGuide';
var idx = 0;

class Bezier {
  constructor(container, options) {
    this.options = options;
    this.stage = container.stage;
    this.container = new Container();
    container.addChild(this.container);
    this._shapes = {};
    this.pivots = [];
    this.state = 'start';
    this.a = null;
    this.b = null;
    this.c = null;
    this.d = null;
    this.mouse_pressed = false;
  }

  cancel() { // calling on remove figure

  }
  start() { // calling on set active, and require focus
    if(!this.a) {
      this.onMouseFirstPoint();
    } else {
      this.onMouseLastPoint();
    }
  }


  createPoint(x, y) {
    var pivot = new Circle(3, 'yellow');
    pivot.set({x, y});
    this.container.addChild(pivot);
    return pivot;
  }


  drawBezier(x, y) {
    var a = this.a;
    var b = this.b;
    var c = this.c;
    var d = this.d;

    var width = 3;
    this.shape && this.container.removeChild(this.shape);
    this.shape = new Shape()
      .ss(3)
      .s(black)
      .s('#FF99AA')
      .mt(a.x,a.y+width).bt(b.x,b.y+width, c.x,c.y+width, d.x,d.y+width)
      .es('#AAFF99');
      // .lt(d.x, d.y - width)
      // .mt(d.x,d.y-width).bt(c.x,c.y-width, b.x,b.y-width, a.x,a.y-width)
      // .mt(a.x, a.y+width).cp();
    // this.shape.graphics
    //   .beginFill('#ff99aa')
    //   .endFill('#aaff99')
    //   .setStrokeStyle(8, "round");
    this.shape.addTo(this.container);
    this.stage.update();
  }

  setPrevious(bezier) {
    this.a = bezier.d;
    this.b = bezier.cc;
  }

  createGuide1(x, y) {
    this.guide1Render = new BezierGuide(this.container, x, y);
    this.b = {x, y};
    this.bb = {x, y};
  }

  createGuide2(x, y) {
    this.guide2Render = new BezierGuide(this.container, x, y);
    this.c = {x, y};
    this.cc = {x, y};
  }

  onMousedown1({stageX: x, stageY: y}) {
    this.mouse_pressed = true;
    this.pivot1 = this.createPoint(x, y);
    this.a = {x, y};
    this.createGuide1(x, y);

    this.stage.update();
  }

  onMousemove1({stageX: x, stageY: y, nativeEvent: {altKey}}) {
    debug(`x: ${x}, y: ${y}`);
    if(this.mouse_pressed) {
      // this.drawHandler(x, y, altKey);
      this.guide1Render.setGuide1(x, y);

      if(!altKey) {
        this.guide1Render.setGuide2(2*this.a.x - x, 2*this.a.y - y);
      }
      this.b = this.guide1Render.guide1;
      this.bb = this.guide1Render.guide2;
    }
  }

  onMouseup1(e) {
    this.mouse_pressed = false;
    this.offMouse();
    this.onMouseLastPoint();
  }

  onMousedown2({stageX: x, stageY: y}) {
    this.mouse_pressed = true;

    this.pivot2 = this.createPoint(x, y);
    this.d = {x, y};
    this.createGuide2(x, y);

    this.stage.update();
  }

  onMousemove2({stageX: x, stageY: y, nativeEvent: {altKey}}) {

    if(this.mouse_pressed) {
      this.guide2Render.setGuide1(x, y);

      if(!altKey) {
        this.guide2Render.setGuide2(2*this.d.x - x, 2*this.d.y - y);
      }
      this.c = this.guide2Render.guide2;
      this.cc = this.guide2Render.guide1;

      this.drawBezier(x, y);
    } else {
      this.d = {x, y};
      this.c = {x, y};
      this.drawBezier(x, y);
    }
  }

  onMouseup2({stageX: x, stageY: y}) {
    this.drawBezier(x, y);
    debug(`Mouse up x:${x} y:${y}`);

    this.mouse_pressed = false;
    this.offMouse();
    console.log('Bezier completed:', [
      [this.a.x, this.a.y],
      [this.b.x, this.b.y],
      [this.c.x, this.c.y],
      [this.d.x, this.d.y],
    ]);
    this.options.onComplete();
  }

  onMouse() {
    this.stagemousedown = this.stage.on('stagemousedown', this.onMousedown.bind(this));
    this.stagemousemove = this.stage.on('stagemousemove', this.onMousemove.bind(this));
    this.stagemouseup = this.stage.on('stagemouseup', this.onMouseup.bind(this));
  }

  onMouseFirstPoint() {
    this.stagemousedown = this.stage.on('stagemousedown', this.onMousedown1.bind(this));
    this.stagemousemove = this.stage.on('stagemousemove', this.onMousemove1.bind(this));
    this.stagemouseup = this.stage.on('stagemouseup', this.onMouseup1.bind(this));
  }

  onMouseLastPoint() {
    this.stagemousedown = this.stage.on('stagemousedown', this.onMousedown2.bind(this));
    this.stagemousemove = this.stage.on('stagemousemove', this.onMousemove2.bind(this));
    this.stagemouseup = this.stage.on('stagemouseup', this.onMouseup2.bind(this));
  }

  offMouse() {
    this.stage.off('stagemousedown', this.stagemousedown);
    this.stage.off('stagemousemove', this.stagemousemove);
    this.stage.off('stagemouseup', this.stagemouseup);
  }

  revert() {
    this.container.removeChild(this.pivot2);
    this.guide2Render.cleanup();
    this.onMouseLastPoint();
  }
}

export default Bezier;
