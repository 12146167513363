
import LevelStart from './Level/Start';
import LevelFinish from './Level/Finish';
import LevelBezier from './Level/Bezier';
import LevelRectangle from './Level/Rectangle';

class Game {
  constructor(stage) {
    this.stage = stage;
    this.steps = [
      LevelStart,
      LevelRectangle,
      LevelBezier,
      LevelFinish,
    ];
  }

  start() {
    // this.container && this.stage.removeChild(this.container);
    this.container = new Container();
    this.stage.addChild(this.container);

    this.level_idx = 0;
    this.nextStep();
  }

  nextStep() {
    var level = this.steps[this.level_idx];
    this.level = new level(this.stage, { onFinish: this.onFinishStep.bind(this)});
    console.log('start level', level.name, this.level_idx);
    this.level.start();
  }

  onFinishStep() {
    this.level.finish();
    this.level_idx++;
    console.log('idx', this.level_idx);
    this.nextStep();
  }

}

export default Game;
