

class LevelFinish {
  constructor(stage, options) { // options: {onFinish}
    this.options = options;
    this.stage = stage;
  }

  start() {
    this.container = new Container();
    this.stage.addChild(this.container);
    this.container.center(this.stage);

    // TODO расширить класс контейнер, который будет хранить и удалять все нарисованные компоненты

    var button = new Button(300,60,"This is your gift");
    button.center(this.container);
    // button.on("click", this.options.onFinish);

    this.stage.update();
  }


  finish() {
    this.stage.removeChild(this.container);
  }
}

export default LevelFinish;
