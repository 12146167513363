import '../styles/index.scss';
import BezierChain, { isPathSimilar } from './BezierChain';
import GameLevel from './GameLevel';
import Game from './Game';

// SCALING OPTIONS
// scaling can have values as follows with full being the default
// "fit"	sets canvas and stage to dimensions and scales to fit inside window size
// "outside"	sets canvas and stage to dimensions and scales to fit outside window size
// "full"	sets stage to window size with no scaling
// "tagID"	add canvas to HTML tag of ID - set to dimensions if provided - no scaling

const scaling = "full"; // this will resize to fit inside the screen dimensions
const width = 1024;
const height = 768;
const color = '#393939'; // ZIM colors like green, blue, pink, faint, clear, etc.
const outerColor = '#333'; // any HTML colors like "violet", "#333", etc. are fine to use


function renderBezierChain(stage, curves) {
  for(var i = 0; i < curves.length; i++) {
    var curve = curves[i];
    renderBezier(stage, curve.a, curve.b, curve.c, curve.d);
  }
}

function renderBezier(stage, a, b, c, d) {
  var g = new createjs.Shape();
  g.graphics.s("#ffdd00").mt(a.x, a.y).bt(b.x, b.y, c.x, c.y, d.x, d.x);
  stage.addChild(g);

  var g = new createjs.Shape();
  g.graphics.s("#777").mt(a.x, a.y).lt(b.x, b.y);
  stage.addChild(g);

  var g = new createjs.Shape();
  g.graphics.s("#777").mt(d.x, d.y).lt(c.x, c.y);
  console.log(d, c);
  stage.addChild(g);
}

const frame = new Frame(scaling, width, height, color, outerColor);
frame.on("ready", () => { // ES6 Arrow Function - like function(){}
    zog("ready from ZIM Frame"); // logs in console (F12 - choose console)

    const stage = frame.stage;
    let stageW = frame.width;
    let stageH = frame.height;


    var template = [
      { a: {x: 100, y: 100}, b: {x: 100, y: 75}, c: {x: 200, y: 75}, d: {x: 200, y: 200} }
      , { a: {x: 200, y: 200}, b: {x: 210, y: 75}, c: {x: 300, y: 75}, d: {x: 300, y: 300} }
      , { a: {x: 300, y: 300}, b: {x: 290, y: 75}, c: {x: 400, y: 75}, d: {x: 400, y: 400} }
    ];
    // var level = new GameLevel(stage, template, {onComplete: function() {
    //   console.log('Complete Level');
    // }});
    // level.start();
    // renderBezierChain(stage, template);

    // var toolbox = new ToolboxStage(stage);
    // toolbox.start();
    var game = new Game(stage);
    game.start();
    // stage.update(); // this is needed to show any changes

}); // end of ready

export const debug = function(data) {
  // var s = document.createElement('div');
  // s.innerText = data;
  // document.getElementById('debug').prepend(
  //   s
  // );
};

export const score = function(name, data) {
  // var item = document.getElementById('name');
  // if(!item) {
  //   item = document.createElement('div');
  //   item.id = name;
  //   document.getElementById('score').prepend(
  //     item
  //   );
  // }
  // item.innerHTML = `${name}: ${data}`;
};
