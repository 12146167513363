
import BezierChain, { chainDifferentElement } from '../BezierChain';
import { renderBezierChain } from '../helpers';
import { score } from '../index';

class LevelRectangle {
  constructor(stage, options) { // options: {onFinish}
    this.options = options;
    this.stage = stage;
    this.template = [
      { a: {x: 100, y: 100}, b: {x: 100, y: 100}, c: {x: 200, y: 200}, d: {x: 200, y: 200} }
      , { a: {x: 200, y: 200}, b: {x: 200, y: 200}, c: {x: 200, y: 250}, d: {x: 200, y: 250} }
    ];
  }

  start() {
    this.container = new Container(600, 800);
    this.stage.addChild(this.container);
    // this.container.center(this.stage);

    renderBezierChain(this.container, this.template);

    this.chain = new BezierChain(this.container, {
      onCompleteStep: this.onCompleteStep.bind(this),
      limit: this.template.length,
    });
    this.chain.start();

    this.stage.update();
  }

  onCompleteStep() {
    var user_curves = this.chain.segments();
    var diff = chainDifferentElement(this.template, user_curves);
    if(diff !== -1) {
      this.chain.revertLastBezier();
    } else if(!this.chain.isFinished()) {
      this.chain.addBezier();
    } else {
      this.containerFinish = new Container(600, 800);
      this.stage.addChild(this.containerFinish);
      this.containerFinish.center(this.stage);

      var button = new Button(200,60, "Next level");
      button.center(this.containerFinish);
      button.on("click", this.options.onFinish);
    }
    this.stage.update();
    score('diff', diff);
    console.log('chainDifferentElement', diff);
  }

  finish() {
    this.stage.removeChild(this.container, this.containerFinish);
  }
}

export default LevelRectangle;


function simpleCompare(a, b, c, d) {
  return Math.pow(pointDistance(a[0], a[1]), 2) + Math.pow(pointDistance(b[0], b[1]), 2) + Math.pow(pointDistance(c[0], c[1]), 2) + Math.pow(pointDistance(d[0], d[1]), 2);
}

function complexCompare(a, b, c, d) {
  return Math.pow(pointDistance(b[0], a[0]) - pointDistance(b[1], a[1]), 2) + Math.pow(pointDistance(c[0], b[0]) - pointDistance(c[1], b[1]), 2) + Math.pow(pointDistance(d[0], c[0]) - pointDistance(d[1], c[1]), 2);
}

function pointDistance(a, b) {
  return Math.sqrt(Math.pow(Math.abs(a.x-b.x), 2) + Math.pow(Math.abs(a.y - b.y), 2));
}
