import { debug } from './index';

class BezierGuide {
  constructor(container, x , y) {
    this.stage = container.stage;
    this.container = new Container();
    container.addChild(this.container);
    this.pivot = {x, y};
    this.guide1 = {x, y};
    this.guide2 = {x, y};
  }

  setPivot(x, y) {
    this.pivot = {x, y};
    this.render();
  }

  setGuide1(x, y) {
    this.guide1 = {x, y};
    this.render();
  }

  setGuide2(x, y) {
    this.guide2 = {x, y};
    this.render();
  }

  render() {
    const { pivot, container, guide1, guide2 } = this;

    container.removeAllChildren();

    container.addChild(
      createGuideStroke(pivot, guide1, 'handler1')
      , createGuide('guide1', guide1.x, guide1.y)
      , createGuideStroke(pivot, guide2, 'handler2')
      , createGuide('guide2', guide2.x, guide2.y)
    );

    this.stage.update();
  }

  cleanup() {
    this.container.removeAllChildren();
  }

  remove() {
    this.stage.removeChild(this.container);
  }

}

export default BezierGuide;

function createGuide(name, x, y){
  var c = new Circle(2, 'black');
  c.set({x, y});
  c.name = name;
  return c;
}


var createGuideStroke = function(a, b, name) {
  var s = new Shape(a.x, a.y);
  var g = s.graphics;
  s.name = name;
  g.setStrokeStyle(1).beginStroke("black");
  g.moveTo(a.x, a.y);
  g.lineTo(b.x, b.y);
  g.endStroke();
  return s;
};
