
import BezierChain, { chainDifferentElement } from '../BezierChain';
import { renderBezierChain } from '../helpers';

class LevelStart {
  constructor(stage, options) { // options: {onFinish}
    this.options = options;
    this.stage = stage;
    this.template = [
      { a: {x: 100, y: 100}, b: {x: 100, y: 75}, c: {x: 200, y: 75}, d: {x: 200, y: 200} }
      , { a: {x: 200, y: 200}, b: {x: 210, y: 75}, c: {x: 300, y: 75}, d: {x: 300, y: 300} }
      // , { a: {x: 300, y: 300}, b: {x: 290, y: 75}, c: {x: 400, y: 75}, d: {x: 300, y: 300} }
    ];
  }

  start() {
    this.container = new Container(600, 800);
    this.stage.addChild(this.container);
    // this.container.center(this.stage);

    renderBezierChain(this.container, this.template);

    this.chain = new BezierChain(this.container, {
      onCompleteStep: this.onCompleteStep.bind(this),
      limit: this.template.length,
    });
    this.chain.start();

    this.stage.update();
  }

  onCompleteStep() {
    var user_curves = this.chain.segments();
    var diff = chainDifferentElement(this.template, user_curves);
    if(diff !== -1) {
      this.chain.revertLastBezier();
    } else if(!this.chain.isFinished()) {
      this.chain.addBezier();
    } else {
      this.containerFinish = new Container(600, 800);
      this.stage.addChild(this.containerFinish);
      this.containerFinish.center(this.stage);

      var button = new Button(200,60, "Awesome");
      button.center(this.containerFinish);
      button.on("click", this.options.onFinish);
    }
    console.log('chainDifferentElement', diff);
    this.stage.update();
  }

  finish() {
    this.stage.removeChild(this.container, this.containerFinish);
  }
}

export default LevelStart;

//
//
// function ToolboxStage(stage) {
//   var activeTool = null;
//   var tools = [];
//   var activeTool = null;
//
//   this.setActiveTool = (tool)=> {
//     if(!!activeTool) {
//       activeTool.cancel();
//     }
//     activeTool = new tool(stage, {onComplete: this.onComplete});
//     activeTool.start();
//   };
//
//   this.start = () => {
//     this.setActiveTool(BezierChain);
//   };
//
//   this.onComplete = () => {
//     var q = activeTool.curves[0];
//     var a = q.a;
//     var b = q.b;
//     var c = q.c;
//     var d = q.d;
//     console.log([[a.x, a.y], [b.x, b.y], [c.x, c.y], [d.x, d.y]]);
//     var shp = {
//       a: {x: 100, y: 100},
//       b: {x: 100, y: 75},
//       c: {x: 200, y: 75},
//       d: {x: 200, y: 200},
//     };
//     console.log(simpleCompare([a, shp.a], [b, shp.b], [c, shp.c], [d, shp.d]), complexCompare([a, shp.a], [b, shp.b], [c, shp.c], [d, shp.d]));
//   };
//   return this;
// }


// |p1-p1_|^2 + |p2-p2_|^2 + |p3-p3_|^2 + |p4-p4_|^2
// (|p2-p1| - |p2_-p1_|)^2 + (|p3-p2| - |p3_-p2_|)^2 + (|p4-p3| - |p4_-p3_|)^2

function simpleCompare(a, b, c, d) {
  return Math.pow(pointDistance(a[0], a[1]), 2) + Math.pow(pointDistance(b[0], b[1]), 2) + Math.pow(pointDistance(c[0], c[1]), 2) + Math.pow(pointDistance(d[0], d[1]), 2);
}

function complexCompare(a, b, c, d) {
  return Math.pow(pointDistance(b[0], a[0]) - pointDistance(b[1], a[1]), 2) + Math.pow(pointDistance(c[0], b[0]) - pointDistance(c[1], b[1]), 2) + Math.pow(pointDistance(d[0], c[0]) - pointDistance(d[1], c[1]), 2);
}

function pointDistance(a, b) {
  return Math.sqrt(Math.pow(Math.abs(a.x-b.x), 2) + Math.pow(Math.abs(a.y - b.y), 2));
}
