
export function renderBezierChain(container, curves) {

  function renderBezier(a, b, c, d) {
    var g = new createjs.Shape();
    console.log([a.x, a.y, b.x, b.y, c.x, c.y, d.x, d.y]);
    g.graphics.s("#ffdd00").mt(a.x, a.y).bt(b.x, b.y, c.x, c.y, d.x, d.y);
    container.addChild(g);

    var g = new createjs.Shape();
    g.graphics.s("#777").mt(a.x, a.y).lt(b.x, b.y);
    container.addChild(g);

    var g = new createjs.Shape();
    g.graphics.s("#777").mt(d.x, d.y).lt(c.x, c.y);
    container.addChild(g);

    var g = new Circle(2, 'green');
    g.loc(d.x, d.y);
    container.addChild(g);

    var g = new Circle(2, 'green');
    g.loc(a.x, a.y);
    container.addChild(g);
  }

  for(var i = 0; i < curves.length; i++) {
    var curve = curves[i];
    console.log('Render bezier', curve);
    renderBezier(curve.a, curve.b, curve.c, curve.d);
  }
}
