import Bezier from './Bezier';
class BezierChain {
  constructor(container, options) {
    this.options = options;
    this.container = container;
    this.limit = options.limit || 3;
    this.curves = [];
  }

  start() { // calling on set active, and require focus
    this.addBezier();
  }

  addBezier() {
    var activeCurve = new Bezier(this.container, {onComplete: this.onComplete.bind(this)});
    if(this.curves.length) {
      activeCurve.setPrevious(this.curves[this.curves.length-1]);
    }
    activeCurve.start();
    this.curves.push(activeCurve);
  }

  onComplete() {
    this.options.onCompleteStep();
  }

  segments() {
    return this.curves;
  }

  isFinished() {
    return this.curves.length === this.limit;
  }

  revertLastBezier() {
    this.curves[this.curves.length-1].revert();
  }
}

// Возвращает номер несовпадающего элемента, или -1 если линии похоже по погрешности diffTreshold
function chainDifferentElement(original, user_curves, diffTreshold) {
  diffTreshold = diffTreshold || 500;
  var diffs = [];
  for(var i = 0; i < user_curves.length; i++) {
    var original_curve = original[i];
    var user_curve = user_curves[i];
    var diff = simpleCompare(
      [original_curve.a, user_curve.a],
      [original_curve.b, user_curve.b],
      [original_curve.c, user_curve.c],
      [original_curve.d, user_curve.d]
    );
    console.log(`Step ${i} and diff ${diff}`, [
      [original_curve.a, user_curve.a],
      [original_curve.b, user_curve.b],
      [original_curve.c, user_curve.c],
      [original_curve.d, user_curve.d]
    ]);
    diffs.push(diff);
    if(diff > diffTreshold) {
      return i;
    }
  }
  return -1 ;
}


function simpleCompare(a, b, c, d) {
  return Math.pow(pointDistance(a[0], a[1]), 2) + Math.pow(pointDistance(b[0], b[1]), 2) + Math.pow(pointDistance(c[0], c[1]), 2) + Math.pow(pointDistance(d[0], d[1]), 2);
}

function complexCompare(a, b, c, d) {
  return Math.pow(pointDistance(b[0], a[0]) - pointDistance(b[1], a[1]), 2) + Math.pow(pointDistance(c[0], b[0]) - pointDistance(c[1], b[1]), 2) + Math.pow(pointDistance(d[0], c[0]) - pointDistance(d[1], c[1]), 2);
}

function pointDistance(a, b) {
  return Math.sqrt(Math.pow(Math.abs(a.x-b.x), 2) + Math.pow(Math.abs(a.y - b.y), 2));
}

export { chainDifferentElement };
export default BezierChain;
